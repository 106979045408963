<form #form (ngSubmit)="load()" [appApiAction]="formPromise" class="container" ngNativeValidate>
  <div class="row justify-content-center mt-5">
    <div class="col-12">
      <h1 class="lead text-center mb-4">Collegamenti</h1>
    </div>
    <div class="col-12 text-center" *ngIf="creatorIdentifier != null">
      <p>{{ "sendCreatorIdentifier" | i18n : creatorIdentifier }}</p>
    </div>
    <div class="col-8" *ngIf="hideEmail">
      <app-callout type="warning" title="{{ 'warning' | i18n }}">
        {{ "viewSendHiddenEmailWarning" | i18n }}
        <a href="https://bitwarden.com/help/receive-send/" target="_blank">{{
          "learnMore" | i18n
        }}</a
        >.
      </app-callout>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-5">
      <div class="card d-block">
        <div class="card-body" *ngIf="loading" class="text-center">
          <i
            class="bwi bwi-spinner bwi-spin bwi-2x text-muted"
            title="{{ 'loading' | i18n }}"
            aria-hidden="true"
          ></i>
          <span class="sr-only">{{ "loading" | i18n }}</span>
        </div>
        <div class="card-body" *ngIf="!loading && passwordRequired">
          <p>{{ "sendProtectedPassword" | i18n }}</p>
          <p>{{ "sendProtectedPasswordDontKnow" | i18n }}</p>
          <div class="form-group">
            <label for="password">{{ "password" | i18n }}</label>
            <input
              id="password"
              type="password"
              name="Password"
              class="text-monospace form-control"
              [(ngModel)]="password"
              required
              appInputVerbatim
              appAutofocus
            />
          </div>
          <div class="d-flex">
            <button
              type="submit"
              class="btn btn-primary btn-block btn-submit"
              [disabled]="form.loading"
            >
              <span>
                <i class="bwi bwi-sign-in" aria-hidden="true"></i> {{ "continue" | i18n }}
              </span>
              <i
                class="bwi bwi-spinner bwi-spin"
                title="{{ 'loading' | i18n }}"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
        <div class="card-body" *ngIf="!loading && unavailable">
          {{ "sendAccessUnavailable" | i18n }}
        </div>
        <div class="card-body" *ngIf="!loading && error">
          {{ "unexpectedError" | i18n }}
        </div>
        <div class="card-body" *ngIf="!loading && !passwordRequired && send">
          <p class="text-center">
            <b>{{ send.name }}</b>
          </p>
          <hr />
          <!-- Text -->
          <ng-container *ngIf="send.type === sendType.Text">
            <app-callout *ngIf="send.text.hidden" type="tip">{{
              "sendHiddenByDefault" | i18n
            }}</app-callout>
            <div class="form-group">
              <textarea
                id="text"
                rows="8"
                name="Text"
                [ngModel]="sendText"
                class="form-control"
                readonly
              ></textarea>
            </div>
            <button
              class="btn btn-block btn-link"
              type="button"
              (click)="toggleText()"
              *ngIf="send.text.hidden"
            >
              <i
                class="bwi bwi-lg"
                aria-hidden="true"
                [ngClass]="{ 'bwi-eye': !showText, 'bwi-eye-slash': showText }"
              ></i>
              {{ "toggleVisibility" | i18n }}
            </button>
            <button class="btn btn-block btn-link" type="button" (click)="copyText()">
              <i class="bwi bwi-copy" aria-hidden="true"></i> {{ "copyValue" | i18n }}
            </button>
          </ng-container>
          <!-- File -->
          <ng-container *ngIf="send.type === sendType.File">
            <p>{{ send.file.fileName }}</p>
            <button
              class="btn btn-primary btn-block"
              type="button"
              (click)="download()"
              *ngIf="!downloading"
            >
              <i class="bwi bwi-download" aria-hidden="true"></i>
              {{ "downloadFile" | i18n }} ({{ send.file.sizeName }})
            </button>
            <button
              class="btn btn-primary btn-block"
              type="button"
              *ngIf="downloading"
              disabled="true"
            >
              <i
                class="bwi bwi-spinner bwi-spin"
                title="{{ 'loading' | i18n }}"
                aria-hidden="true"
              ></i>
            </button>
          </ng-container>
          <p *ngIf="expirationDate" class="text-center text-muted">
            Expires: {{ expirationDate | date : "medium" }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 text-center mt-5 text-muted">
      <p class="mb-0">
        {{ "sendAccessTaglineProductDesc" | i18n }}<br />
        {{ "sendAccessTaglineLearnMore" | i18n }}
        <a href="https://supporto.clanto.it/passwordmanager/collegamenti" target="_blank"
          >Collegamenti</a
        >
        {{ "sendAccessTaglineOr" | i18n }}
        <a href="https://password.clnt.eu/#/register" target="_blank">{{
          "sendAccessTaglineSignUp" | i18n
        }}</a>
        {{ "sendAccessTaglineTryToday" | i18n }}
      </p>
    </div>
  </div>
</form>
